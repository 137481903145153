import ContentsJson from './contents.json'

export default function Embed() {
    return (
        <div className="relative overflow-hidden">
            <main>
                <div className="bg-gray-900 pt-16">
                    <div className="mx-auto max-w-7xl">
                        <div className="mx-auto max-w-2xl px-6 text-center">
                            <h1 className="mt-5 text-5xl font-extrabold tracking-tight text-white">
                                <span className="block text-indigo-400">
                                    {ContentsJson.greeting[0]}
                                </span>
                                <span className="mt-3 block text-white">
                                    {ContentsJson.greeting[1]}
                                </span>
                            </h1>

                            {ContentsJson.intro.map((item) => (
                                <p className="mt-5 text-justify text-xl text-gray-300">
                                    {item}
                                </p>
                            ))}
                        </div>
                        <div>
                            <div className="relative">
                                <div className="absolute inset-x-0 bottom-0 h-1/2" />
                                <div className="mx-auto max-w-7xl px-6">
                                    <div className="relative overflow-hidden rounded-2xl shadow-xl">
                                        <div className="relative px-6 py-24">
                                            <h1 className="text-center text-5xl font-extrabold tracking-tight">
                                                <span className="block text-indigo-200">
                                                    Work Experience.
                                                </span>
                                            </h1>
                                            {ContentsJson.career.map((item) => (
                                                <div
                                                    key={item.company}
                                                    className="mt-10 flex flex-col gap-y-5 text-left text-xl  text-white"
                                                >
                                                    <h1 className="mx-auto text-3xl font-extrabold tracking-tight">
                                                        {item.company}
                                                    </h1>
                                                    <p className="max-w-lg text-left">
                                                        {item.position}
                                                        <br />
                                                        {item.date_range}
                                                    </p>
                                                    {item.projects.map(
                                                        (project) => (
                                                            <>
                                                                <p className="max-w-lg text-left text-indigo-200">
                                                                    {
                                                                        project.description
                                                                    }
                                                                </p>
                                                                <ul className="mx-auto max-w-lg list-disc text-left">
                                                                    {project.tasks.map(
                                                                        (
                                                                            task
                                                                        ) => (
                                                                            <li className="mb-4">
                                                                                {
                                                                                    task
                                                                                }
                                                                            </li>
                                                                        )
                                                                    )}
                                                                </ul>
                                                                <p>
                                                                    <span className="text-indigo-200">
                                                                        Tech
                                                                        Stack :{' '}
                                                                    </span>
                                                                    {project.tech_stack.join(
                                                                        ', '
                                                                    )}
                                                                </p>
                                                            </>
                                                        )
                                                    )}
                                                </div>
                                            ))}
                                        </div>

                                        <div className="relative px-6 py-24">
                                            <h1 className="text-center text-5xl font-extrabold tracking-tight">
                                                <span className="block text-indigo-200">
                                                    Projects.
                                                </span>
                                            </h1>
                                            {ContentsJson.projects.map(
                                                (item) => (
                                                    <div
                                                        key={item.title}
                                                        className="gap-y-5text-left mt-10 flex flex-col text-xl text-white"
                                                    >
                                                        <p className="mx-auto mb-5 break-keep text-center text-3xl font-extrabold tracking-tight">
                                                            {item.title}
                                                        </p>
                                                        <p className="max-w-lg text-left">
                                                            {item.position}
                                                            <br />
                                                            {item.date_range}
                                                        </p>
                                                        <ul className="mx-auto max-w-lg list-disc text-left">
                                                            {item.tasks.map(
                                                                (task) => (
                                                                    <li className="mb-4">
                                                                        {task}
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                        <p>
                                                            <span className="text-indigo-200">
                                                                Tech Stack :{' '}
                                                            </span>
                                                            {item.tech_stack.join(
                                                                ', '
                                                            )}
                                                        </p>
                                                    </div>
                                                )
                                            )}
                                        </div>

                                        <div className="relative py-8 px-6">
                                            <h1 className="text-center text-5xl font-extrabold tracking-tight">
                                                <span className="block text-indigo-200">
                                                    Education.
                                                </span>
                                            </h1>
                                            <div className="mt-10 flex flex-col gap-y-5 text-left text-xl  text-white">
                                                <h1 className="mx-auto text-3xl font-extrabold tracking-tight">
                                                    아주대학교
                                                </h1>
                                                <p className="max-w-lg text-left">
                                                    경제학과 학사 <br />
                                                    2012.03 ~ 2018.02
                                                </p>
                                            </div>
                                        </div>

                                        <div className="relative py-8 px-6">
                                            <h1 className="text-center text-5xl font-extrabold tracking-tight">
                                                <span className="block text-indigo-200">
                                                    Overall.
                                                </span>
                                            </h1>
                                            <div className="mt-10 flex flex-col gap-y-5 text-left text-xl  text-white">
                                                <ul className="mx-auto max-w-lg list-disc text-left">
                                                    {ContentsJson.skiils.overall.map(
                                                        (skill) => (
                                                            <li>{skill}</li>
                                                        )
                                                    )}
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="relative py-8 px-6">
                                            <h1 className="text-center text-5xl font-extrabold tracking-tight">
                                                <span className="block text-indigo-200">
                                                    Web.
                                                </span>
                                            </h1>
                                            <div className="mt-10 flex flex-col gap-y-5 text-left text-xl  text-white">
                                                <ul className="mx-auto max-w-lg list-disc text-left">
                                                    {ContentsJson.skiils.web.map(
                                                        (web) => (
                                                            <li>{web}</li>
                                                        )
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}
